<template>
  <Page>

    <transition name="fade" mode="out-in">
      <div class="register" v-if="!isRegistered">
        <form class="register__form"  v-on:submit.prevent="sendRegisterRequest">
          <BlockTitle class="register__form__title">{{$t('auth.create_account').toUpperCase()}}</BlockTitle>

          <p class="register__alert register__alert--wrong" v-if="wrongRegistration">
            {{$t('error.wrong_registration')}}
          </p>

          <InputEmail
            name="email"
            :label="$t('common.email')"
            v-model.trim="email"
            :focusOnMount="true"
            :isRequired="true"
          ></InputEmail>

          <InputPassword
            name="password"
            :label="$t('common.password')"
            v-model.trim="password"
            :isRequired="true">
          </InputPassword>

          <InputPassword
            name="password_repeat"
            :label="$t('auth.password_repeat')"
            v-model.trim="password2"
            :isRequired="true">
            <span v-if="passwordsDontMatch">
              {{$t('auth.passwords_dont_match')}}
            </span>
          </InputPassword>


          <InputText
            name="invitation_code"
            :label="$t('auth.invitation_code')"
            v-model.trim="invitation_code"
            :isRequired="true"
          >
          </InputText>

          <InputCheckbox
            name="tos"
            v-model="isTosAccepted"
            :isRequired="true">
            <template #header>
              <span>
                {{$t('auth.accept_tos_start')}} <router-link :to="{name: 'tos'}">
                  {{$t('common.tos')}}
                </router-link> {{$t('auth.accept_tos_end')}}
              </span>
            </template>
            </InputCheckbox>

          <Button
            :isLoading="isLoading"
            :isDisabled="isInvalid"
            :classes="['btn--accent', 'btn--wide']"
            @onclick="sendRegisterRequest()">
              <span v-show="!isLoading">{{$t('auth.create_account')}}</span>
              <span v-show="isLoading">{{$t('auth.creating_account')}}</span>
          </Button>

          <p class="register__link">
            {{$t('auth.already_registered')}}
            <router-link :to="{name: 'login', params: { locale: $i18n.locale }}">
              {{$t('auth.login')}}
            </router-link>
          </p>

        </form>
      <div class="register__description">
        <img class="register__description__img" src="/img/img-dossier.jpg" alt="">
        <div class="register__description__text">
          <span>{{$t('auth.registration_description')}} <router-link :to="{name: 'contact'}">
                {{$t('auth.contact_link')}}
              </router-link>.</span>
        </div>
      </div>
    </div>

    </transition>


    <transition name="fade" mode="out-in">
      <div v-if="isRegistered" class="auth">
        <div class="auth__header">
          <h1 class="auth__title">{{$t('auth.account_registered')}}</h1>
        </div>
        <p class="auth__tip">{{$t('auth.account_registered_tip')}}</p>

        <p class="auth__link">
          <router-link :to="{name: 'login', params: { locale: $i18n.locale }}">
            {{$t('common.back_to_home')}}
          </router-link>
        </p>
      </div>
    </transition>

  </Page>
</template>

<script>
import Check from '@/services/check/Check';
import InputEmail from '@/components/inputs/email/InputEmail.vue';
import InputPassword from '@/components/inputs/password/InputPassword.vue';

export default {
  name: 'Register',
  components: {
    InputEmail,
    InputPassword,
  },
  data() {
    return {
      email: '',
      password: '',
      password2: '',
      invitation_code: '',
      isTosAccepted: false,
      isLoading: false,
      isRegistered: false,
      wrongRegistration: false,
    };
  },
  computed: {
    isInvalid() {
      return !Check.isValidEmail(this.email)
        || !Check.isValidPassword(this.password)
        || !this.invitation_code
        || this.password !== this.password2
        || !this.isTosAccepted;
    },
    passwordsDontMatch() {
      return Check.isValidPassword(this.password2)
        && this.password !== this.password2;
    },
  },
  methods: {
    sendRegisterRequest() {
      this.isLoading = true;

      this.$store.dispatch('registerUser', {
        email: this.email,
        password: this.password2,
        invitation_code: this.invitation_code,
      }).then(() => {
        this.isRegistered = true;
      })
        .catch(() => {
          this.wrongRegistration = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__form {
    width: 50%;
    min-width: var(--layout-max-width-smallest);
    &__title {
      text-align: center;
      padding-top: 0
    }
  }
  &__description {
    max-width: 600px;
    // margin-right: -250px !important;
    // margin-left: -250px !important;
    &__img {
      width:100%;
      height:auto;
    }
    &__text {
      margin: 25px 100px;
      text-align: center;
    }
  }

  &__tip {
    text-align: center;
  }
  &__tos {
    font-size: 12px;
    text-align: center;
  }


  &__link {
    text-align: center;
    margin-top: 26px;
    font-size: 14px;
    a {
      font-weight: 500;
    }
  }


  &__alert {
    text-align: center;
    &--wrong {
      color: var(--error-color-strong);
      text-align: center;
      font-weight: 500;
    }
  }

  .btn--wide {
    margin-top: 16px;
  }
  .input-block__required {
    display: none;
  }
}

@media only screen and (max-width: 779px) {
  .register {
    &__form {
      width: 100%;
      min-width: 0;
    }
    &__description {
      display: none;
    }
  }
}

</style>
